import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
  get,
  map,
  isNumber,
  noop,
  isEmpty,
  find,
  keyBy,
  values,
  filter,
  sumBy,
  sum,
} from "lodash";
import { makeStyles } from "../AppContainer/mui-theme";
import UserAvatar from "../UserAvatar";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  IconButton,
  Badge,
  Button,
  Avatar,
  ButtonGroup,
  Grid,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import UnCheckIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LockIcon from "@mui/icons-material/LockOutlined";
import OthersIcon from "@mui/icons-material/SupervisorAccountOutlined";

export default function CheckItems({
  checkDetails,
  context,
  loading,
  onChange,
}) {
  const { classes } = useStyles();

  const {
    pageContext: {
      business: { currencySymbol },
    },
    user,
    appStyles,
    T,
  } = context;
  const checkItems = get(checkDetails, "check.checkItems");

  const [lastUpdate, setLastUpdate] = useState();
  useEffect(() => {
    setLastUpdate(getCurrentTime());
  }, [checkDetails]);

  const isClosed = Boolean(get(checkDetails, "check.closedDate"));

  const checkItemsForUpdate = keyBy(
    map(checkItems, ({ itemId, lockedForPaymentBy }) => ({
      itemId,
      count: get(find(lockedForPaymentBy, "me"), "lockCount", 0),
    })),
    "itemId",
  );

  const checkUsers = get(checkDetails, "check.users", []);

  const withOthers = !isEmpty(checkUsers);

  const handleChangeItem = (items, lastUpdate) => (itemChange) => {
    const nextCheckItems = values({
      ...items,
      [itemChange.itemId]: itemChange,
    });

    onChange(nextCheckItems);
  };

  const MyAvatar = useCallback(
    (props) => (
      <UserAvatar user={user} T={T} appStyles={appStyles} {...props} />
    ),
    [user],
  );

  const OthersAvatar = useCallback(
    ({ size = "medium", ...props }) =>
      checkUsers.length > 1 ? (
        <Avatar className={size === "small" && classes.smallAvatar}>
          <OthersIcon />
        </Avatar>
      ) : (
        <UserAvatar
          T={T}
          color={appStyles.accentColor}
          name={checkUsers[0]}
          appStyles={appStyles}
          size={size === "small" ? 32 : 38}
          {...props}
        />
      ),
    [checkUsers[0]],
  );

  const ItemCounter = useCallback(
    ({ item, withOthers, onChange = noop, lastUpdate }) => {
      const { classes } = useStyles();

      const maxCount = item.count - (item.countPaid || 0);
      const countByMe = get(
        find(item.lockedForPaymentBy, "me"),
        "lockCount",
        0,
      );
      const countByOthers = sumBy(
        filter(item.lockedForPaymentBy, ({ me }) => !me),
        "lockCount",
      );
      // const others = [{ name: "Yael", count: 1 }];
      const handleChangeItemCount = (count) => {
        onChange({ itemId: item.itemId, count });
      };

      const combined = countByMe > 0 && countByOthers > 0;
      const combinedCount = sum([countByMe, countByOthers]);

      return (
        <Grid
          container
          alignItems="center"
          spacing={1}
          direction="row"
          wrap="nowrap"
        >
          {withOthers && (
            <Grid
              item
              container
              direction="column"
              spacing={1}
              style={{ marginRight: combined ? 2 : 1 }}
            >
              {countByMe > 0 && (
                <Grid item>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    badgeContent={countByMe}
                    color="primary"
                  >
                    <MyAvatar size={combined ? 32 : 38} />
                  </Badge>
                </Grid>
              )}
              {countByOthers > 0 && (
                <Grid item>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    badgeContent={countByOthers}
                    color="primary"
                  >
                    <OthersAvatar size={combined ? "small" : "medium"} />
                  </Badge>
                </Grid>
              )}
            </Grid>
          )}
          <Grid item container direction="column" alignItems="center">
            <Grid item>
              <AddRemoveButton
                T={T}
                onChange={handleChangeItemCount}
                value={countByMe}
                combinedValue={combinedCount}
                maxCount={maxCount}
                hideCount={withOthers}
                lastUpdate={lastUpdate}
                locked={!countByMe && combinedCount === maxCount}
              />
            </Grid>
            {maxCount > 1 && maxCount - combinedCount > 0 && (
              <Grid item>
                <Typography variant="caption">
                  {maxCount - combinedCount} {T("left to take")}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      );
    },
    [lastUpdate],
  );

  const getItemPaymentMessage = useCallback((item) => {
    item.lockedAndPaidBy;
    return (
      isNumber(item.price) &&
      `${item.count > 1 ? `${item.count} X ` : ""}${currencySymbol}${Number(
        item.price,
      ).toFixed(2)}${
        item.countPaid < item.count
          ? ` (${item.count - item.countPaid} ${T("left to pay")})`
          : ""
      }`
    );
  }, []);

  return (
    <List className={classes.root}>
      {!checkItems && loading ? (
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="h2">
                  {isClosed ? T("See you next time") : T("Select items to pay")}
                </Typography>
              }
            />
          </ListItem>
          <Divider />
        </>
      )}
      {map(checkItems, (item, index) => (
        <ListItem
          style={{
            ...(index > 0 && { borderTop: "1px solid #eee" }),
            paddingBottom: 20,
          }}
          disabled={(item.countPaid || 0) === item.count}
          key={item.itemId}
        >
          <ListItemText
            primary={`${
              item.count > 1 ? `${item.count} X ` : ""
            }${item.name.slice(0, 16)}${item.name.length > 16 ? "..." : ""}`}
            secondary={getItemPaymentMessage(item)}
          />
          <ListItemSecondaryAction>
            {(item.countPaid || 0) < item.count ? (
              <ItemCounter
                lastUpdate={lastUpdate}
                item={item}
                withOthers={withOthers}
                onChange={handleChangeItem(checkItemsForUpdate, lastUpdate)}
              />
            ) : (
              <CheckCircleIcon color="secondary" />
            )}
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
}

const getCurrentTime = () => new Date().getTime();

const AddRemoveButton = ({
  value,
  combinedValue,
  onChange = noop,
  maxCount,
  hideCount,
  lastUpdate,
  locked,
  T,
}) => {
  const [lastUpdateTimeFromServer, setLastUpdateTimeFromServer] = useState();
  const [lastUserUpdateTime, setLastUserUpdateTime] = useState();
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value || 0);
    }
  }, [value, lastUpdate]);

  const handleChange = useCallback((nextValue) => {
    onChange(nextValue);
    setCurrentValue(nextValue);
  }, []);
  const hideReduce = !locked && maxCount === 1 && !currentValue;
  const TakeButtonIcon = useCallback(
    () =>
      combinedValue < maxCount ? (
        maxCount === 1 ? (
          <AddIcon fontSize="small" />
        ) : (
          <AddIcon fontSize="small" />
        )
      ) : (
        <CheckIcon fontSize="small" color="primary" />
      ),
    [combinedValue, maxCount],
  );
  const takeButtonDisabled = combinedValue === maxCount;
  return (
    <ButtonGroup>
      {!hideReduce && (
        <Button
          aria-label="reduce"
          variant="outlined"
          disabled={!currentValue}
          onClick={() => {
            handleChange(Math.max(currentValue - 1, 0));
          }}
        >
          {locked ? (
            <LockIcon fontSize="small" />
          ) : maxCount === 1 ? (
            <UnCheckIcon fontSize="small" />
          ) : (
            <RemoveIcon fontSize="small" />
          )}
        </Button>
      )}
      {!hideCount && !hideReduce && (
        <Button disabled>{currentValue || 0}</Button>
      )}
      <Button
        disabled={takeButtonDisabled}
        variant="contained"
        color={takeButtonDisabled ? "default" : "primary"}
        aria-label="increase"
        onClick={() => {
          handleChange(Math.min(currentValue + 1, maxCount));
        }}
      >
        <TakeButtonIcon />
      </Button>
    </ButtonGroup>
  );
};

const useStyles = makeStyles()((theme) => ({
  smallAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

import React, { useMemo } from "react";
import { InputGroup, TextInput } from "../Inputs";
import InlineSelect from "../Inputs/InlineSelect";

import Card from "../Card";
import { compact, map } from "lodash";

const DEFAULT_TIP_OPTIONS = [10, 15, 20, 25];

export default function({
  appStyles,
  value,
  onChange,
  onChangeCustomValue,
  T,
  enableCustomTip,
  currencySymbol,
}) {
  const tipOptions = useMemo(
    () =>
      compact([
        { label: T("No Tip"), value: 0 },
        ...map(
          // _.get(
          //   currentBranch,
          //   `servingOptionTypeToTipOptions.${servingOption.type}`,
          DEFAULT_TIP_OPTIONS,

          (option) => ({
            label: `${option}%`,
            value: option / 100,
          }),
        ),

        enableCustomTip && {
          value: CUSTOM_TIP_OPTION_VALUE,
          label: T(SET_TIP_LABEL),
        },
      ]),
    [enableCustomTip],
  );

  console.log({ tipOptions });

  return (
    <Card appStyles={appStyles}>
      <Card.Title light appStyles={appStyles}>
        {T("Tip")}
      </Card.Title>
      <Card.Content>
        <small>{T("Order price does not include gratuity")}</small>
      </Card.Content>
      <InputGroup tight appStyles={appStyles} T={T}>
        <InlineSelect
          rtl={appStyles.rtl}
          options={tipOptions}
          value={value}
          onChange={onChange}
          selectedColor={appStyles.actionColor}
          T={T}
        />
        {enableCustomTip && value === CUSTOM_TIP_OPTION_VALUE && (
          <React.Fragment>
            <span>
              {T("Custom Tip amount:")} ({currencySymbol})
            </span>
            <TextInput
              pattern="/^\d{1,10}(\.\d{1,2})?$/"
              initialValue={value}
              type="number"
              step="any"
              disabled={value !== CUSTOM_TIP_OPTION_VALUE}
              appStyles={appStyles}
              // style={{
              //   width: "50%",
              //   color:
              //     (offerDeliveryTip
              //       ? order.deliveryTipPercentage
              //       : order.servingOptionTipPercentage) ===
              //     CUSTOM_TIP_OPTION_VALUE
              //       ? undefined
              //       : "transparent",
              // }}
              validator={(value) => value > 0}
              errorMessage={T(
                `You selected ${T(SET_TIP_LABEL)}, but did not enter an amount`,
              )}
              onChange={onChangeCustomValue}
            />
          </React.Fragment>
        )}
      </InputGroup>
    </Card>
  );
}

const CUSTOM_TIP_OPTION_VALUE = "custom";
const SET_TIP_LABEL = "Set Tip";

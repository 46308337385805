import React, { useEffect, useState } from "react";
import AppContainer from "../AppContainer";
import PurchasePoller from "../purchase-poller";
import { makeStyles } from "../AppContainer/mui-theme";
import { QRCodeSVG } from "qrcode.react";
import Loader from "../Loader";

export default ({
  T,
  appStyles,
  user,
  app,
  paymentTypeDetails,
  pageContext,
  loadPaymentMethods,
  loadLoyaltyProfile,
  hasChargeCard,
  hasChargeCardDiscount,
}) => {
  const context = {
    appStyles,
    user,
    pageContext,
    app,
    paymentTypeDetails,
    loadPaymentMethods,
    loadLoyaltyProfile,
    hasChargeCard,
    hasChargeCardDiscount,
  };
  const { PageHeader = {} } = appStyles;
  const { classes } = useStyles();
  const [loaded, setLoaded] = useState();

  useEffect(() => {
    setTimeout(() => {
      console.log({ loaded });
      setLoaded(true);
    }, 300);
  }, []);
  console.log({ loaded, app });
  if (app.isSSR || !loaded) {
    return (
      <AppContainer.CenteredColumn appStyles={appStyles}>
        <Loader appStyles={appStyles} style={{ marginTop: 130 }} />
      </AppContainer.CenteredColumn>
    );
  }

  if (user.loggedIn) {
    return (
      <AppContainer.Content appStyles={appStyles}>
        <AppContainer.CenteredColumn>
          <p style={{ color: appStyles.accentColor, marginTop: 30 }}>
            {T(
              "If you don't order on this App, scan this code at our register every time you visit.",
            )}
          </p>
          <h2 style={PageHeader}>{T("Good things will happen!")}</h2>
          <PurchasePoller T={T} {...context} />
          <div className={classes.qrCodeContainer}>
            <QRCodeSVG
              className="fs-unmask"
              value={
                appStyles.scanWithPhoneNumber
                  ? (_.startsWith(user.userDetails.data.phoneNumber, "1") ? user.userDetails.data.phoneNumber.replace("1", "") : user.userDetails.data.phoneNumber)
                  : user.userDetails.data.externalUserId
              }
              style={{ height: 200, width: 200 }}
            />
          </div>
        </AppContainer.CenteredColumn>
      </AppContainer.Content>
    );
  }
  return (
    <AppContainer.Content appStyles={appStyles}>
      <div>{T("Login to scan when you visit the store")}</div>
    </AppContainer.Content>
  );
};

const useStyles = makeStyles()((theme) => ({
  qrCodeContainer: {
    marginTop: 20,
    height: 220,
    width: 220,
    backgroundColor: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
